import { useEffect, useState } from "react";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { sessionStorage } from "aws-amplify/utils";

import "./UnityGame.css";
import TelemetryHelper from "./utils/TelemetryHelper";

cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

interface UnityGameProps {
  setLoading: (loading: boolean) => void;
  setUnity: (unity: boolean) => void;
}

const UnityGame = ({ setLoading, setUnity }: UnityGameProps) => {
  const [unityInstance, setUnityInstance] = useState<any | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isUnityLoading, setIsUnityLoading] = useState(false);

  const { navigator } = window;

  const IsMobile = () => {
    let a = false;

    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i) ||
      (navigator.userAgent.match(/Mac/i) && "ontouchend" in document)
    )
      a = true;
    else a = false;

    return a;
  };

  const IsIOS = () => {
    return (
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  const CopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful: " + text);
      },
      function (err) {
        console.error("Async: Could not copy text: " + text, err);
      }
    );
  };

  useEffect(() => {
    const loadUnityScript = (src: string) => {
      return new Promise<void>((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
      });
    };

    const loadUnity = async () => {
      if (isUnityLoading) return;
      setIsUnityLoading(true);

      const base = "game";
      const version = "0_1_53";
      try {
        console.log("Loading Unity...");
        await loadUnityScript(`${base}/Build/${version}.loader.js`);
        // @ts-ignore
        const unityInstance = await createUnityInstance(document.querySelector("#unity-canvas"), {
          dataUrl: `${base}/Build/${version}.data`,
          frameworkUrl: `${base}/Build/${version}.framework.js`,
          codeUrl: `${base}/Build/${version}.wasm`,
          streamingAssetsUrl: `${base}/StreamingAssets`,
          companyName: "AdenInteractive",
          productName: "ITHF - Digital AI Legends",
          productVersion: "0.0.1",
          matchWebGLToCanvasSize: true,
        });

        setUnityInstance(unityInstance);

        (window as any).unityGame = unityInstance;
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    const GetAccessToken = () => {
      setLoading(false);
      return token ? token : "";
    };

    (window as any).GetAccessToken = GetAccessToken;
    if (unityInstance === null) loadUnity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, unityInstance]);

  useEffect(() => {
    (window as any).ToFullscreen = () => {
      if (unityInstance) unityInstance.SetFullscreen(1);
    };

    (window as any).ExitFullscreen = () => {
      if (unityInstance) unityInstance.SetFullscreen(0);
    };

    return () => {
      delete (window as any).ToFullscreen;
      delete (window as any).ExitFullscreen;
    };
  }, [unityInstance]);

  const GetGraphQLUrl = () => process.env.REACT_APP_API_URL || "";
  const GetApiKey = () => process.env.REACT_APP_API_KEY || "";
  const GetGATrackingId = () => process.env.REACT_APP_GA_TRACKING_ID || "";
  const ExitGame = () => setUnity(false);
  const SendTelemetry = (data: { category: string; action: string; label: string }) => TelemetryHelper.event(data);

  useEffect(() => {
    (window as any).IsMobile = IsMobile;
    (window as any).CopyToClipboard = CopyToClipboard;
    (window as any).GetGraphQLUrl = GetGraphQLUrl;
    (window as any).GetApiKey = GetApiKey;
    (window as any).GetGATrackingId = GetGATrackingId;
    (window as any).ExitGame = ExitGame;
    (window as any).IsIOS = IsIOS;
    (window as any).SendTelemetry = SendTelemetry;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      const data = await cognitoUserPoolsTokenProvider.getTokens();

      if (data?.idToken?.toString()) setToken(data?.idToken?.toString());
    };

    fetchToken();
  }, []);

  return (
    <div id="unity-container" className={`${IsMobile() ? "unity-mobile" : "unity-desktop"}`}>
      <canvas id="unity-canvas" width={1920} height={1080}></canvas>
    </div>
  );
};

export default UnityGame;
